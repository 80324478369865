import { ConfirmationModal, Form } from 'ck-components';
import {
  PostCreateEventV1,
  removeReservationDetailsValues,
  useEventsCreate,
  useEventsEdit,
  useQueryEventsCategories,
} from 'ck-queries';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { getSavedEventValues, localStorageTyped } from 'ck-utils';

import { EventTypeSection } from './components/EventTypeSection';
import { GeneralSection } from './components/GeneralSection';
import { OrganisationSection } from './components/OrganisationSection';
import { defaultEventValues } from './eventCreateDefaultValues';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface EventCreateFormProps {
  prefill?: Partial<PostCreateEventV1 & { id: string }>;
  isEdit?: boolean;
  isCopy?: boolean;
}

/**
 *
 * Create form for events
 * Separated into GeneralSection and OrganisationSection
 *
 * @param prefill: If provided, the form will be prefilled with the data
 * @param isEdit: If true, the form will treat the prefill as an edit instead of a create
 * @param isCopy: If true, the form will treat the prefill as a copy instead of a create
 * @returns EventCreateForm - Used to create and edit an event
 */
export function EventCreateForm({
  prefill,
  isEdit,
  isCopy,
}: EventCreateFormProps) {
  // Hooks, mutations, state
  const navigate = useNavigate();
  const M_createEvent = useEventsCreate();
  const M_editEvent = useEventsEdit(prefill?.id || '');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const categoryNameToId = useQueryEventsCategories();
  const [singleSelectDivision, setSingleSelectDivision] = useState<string>();

  // Setup form hook
  const form = useForm<PostCreateEventV1>({
    defaultValues: {
      ...(prefill ?? getSavedEventValues()),
    },
  });

  // Extract functions from form hook
  const { reset } = form;

  const cleanForm = () => {
    localStorageTyped.remove('FORM_CREATE_EVENT');
    reset(defaultEventValues);
    setSingleSelectDivision(undefined);
  };

  const onSubmit: SubmitHandler<PostCreateEventV1> = (data) => {
    const submitData = categoryNameToId(data);
    let correctData: PostCreateEventV1 | undefined;

    if (isEdit) {
      M_editEvent.mutateAsync(correctData ?? submitData).then(() => {
        cleanForm();
        navigate('/events/archive');
        window.location.reload();
      });
    } else {
      // Normal create and copy acts as the same here
      M_createEvent.mutateAsync(correctData ?? submitData).then(() => {
        cleanForm();
        navigate('/events/archive');
      });
    }
  };

  const onInvalid: SubmitErrorHandler<PostCreateEventV1> = (data) => {
    console.log('Submit error', data);
  };

  const onSaveDraft = () => {
    removeReservationDetailsValues(form);
    form.setValue('isDraft', true);
    form.handleSubmit(onSubmit)();
  };

  return (
    <div className='max-w-7xl'>
      <Form
        onSubmit={() => setIsConfirmationOpen(true)}
        buttonType='button'
        submitButtonLabel={'Gå vidare'}
        submitButtonStyle='w-52'
        onCleanForm={cleanForm}
        onSaveDraft={onSaveDraft}
        formLocalStorageKey={
          !isEdit && !isCopy ? 'FORM_CREATE_EVENT' : undefined
        }
        form={form}
        isSubmitting={M_createEvent.isLoading}
      >
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          setIsOpen={setIsConfirmationOpen}
          onConfirmation={() => {
            form.setValue('isDraft', false);
            removeReservationDetailsValues(form);
            form.handleSubmit(onSubmit, onInvalid)();
          }}
          modalTitle={isEdit ? 'Redigera event' : 'Publicera event'}
          confirmationLabel={'Publicera'}
        >
          <p className='max-w-md text-lg'>
            {isEdit
              ? `Är du säker på att du vill publicera ändringarna? Dessa blir direkt synliga i appen.`
              : 'Är du säker att du vill publicera eventet nu? Eventet blir direkt synligt i appen.'}
          </p>
        </ConfirmationModal>
        <GeneralSection form={form} />
        <hr />
        <OrganisationSection
          form={form}
          isEdit={Boolean(isEdit)}
          setSingelSelectDivision={setSingleSelectDivision}
          singelSelectDivision={singleSelectDivision}
        />
        <hr />
        <EventTypeSection form={form} isEdit={Boolean(isEdit)} />
      </Form>
    </div>
  );
}
