import {
  Description,
  Form,
  FormLabel,
  FormTextInput,
  Icon,
  InformationModal,
  InformationModalProps,
  Label,
  ToggleRadioButtons,
} from 'ck-components';
import {
  FunctionTypeIdPair,
  PostCreateCommittee,
  useCreateCommittee,
  useDivision,
  useFunctionTypes,
} from 'ck-queries';
import { useEffect, useState } from 'react';

import { UserGroup } from 'ck-assets';
import { useForm } from 'react-hook-form';

export const AddCommitteeModal = (
  props: Omit<InformationModalProps, 'children'> & { divisionId: string }
) => {
  const form = useForm<PostCreateCommittee>();
  const M_createCommittee = useCreateCommittee();
  const { division } = useDivision(props.divisionId);

  const { handleSubmit, reset } = form;
  const [allowCreatePaidEvent, setAllowCreatePaidEvent] = useState(false);
  const allFunctionTypes = useFunctionTypes();

  useEffect(() => {
    if (division && division.mustInheritOrgNumber) {
      form.setValue('orgNumber', division.orgNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [division]);

  const onSubmit = (formData: PostCreateCommittee) => {
    // Compile permissions
    const functionTypes: PostCreateCommittee['functionTypes'] = [];
    if (allowCreatePaidEvent) {
      const paidEventPermissionId = allFunctionTypes.find(
        (ft: FunctionTypeIdPair) => ft.functionType === 'PaidEvents'
      )?.id;
      paidEventPermissionId && functionTypes.push(paidEventPermissionId);
    }

    // Add divisionId to form data
    // Add functionTypes
    const postData = {
      ...formData,
      accountNumber: allowCreatePaidEvent ? formData.accountNumber : null,
      resultUnit: allowCreatePaidEvent ? formData.resultUnit : null,
      divisionId: props.divisionId,
      functionTypes,
    };

    M_createCommittee.mutateAsync(postData).then(() => {
      reset();
      props.setIsOpen(false);
    });
  };

  const canCreatePaidEvents: Array<{
    label: string;
    value: number;
  }> = [
    { label: 'Ja', value: Number(true) },
    { label: 'Nej', value: Number(false) },
  ];

  return (
    <InformationModal
      {...props}
      customStyle='w-[692px]'
      onClose={reset}
      showCloseButton={false}
    >
      <Form
        submitButtonLabel='Skapa och skicka'
        showCancelButton={true}
        form={form}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={() => {
          reset();
          props.setIsOpen(false);
        }}
        centerButtons
        isSubmitting={M_createCommittee.isLoading}
      >
        <FormTextInput
          label='MODERATOR'
          description='En förfrågan om att bli moderator kommer att skickas till angiven e-postadress.'
          name='email'
          parentForm={form}
          errorsAlwaysTakeUpSpace={false}
          options={{
            required: 'E-postadress måste anges',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Ogiltig e-postadress',
            },
          }}
        />

        <FormLabel label='NAMNGE KOMMITTÉ'>
          <FormTextInput
            name='name'
            parentForm={form}
            errorsAlwaysTakeUpSpace={false}
            options={{
              required: 'Namn måste anges',
            }}
          />
        </FormLabel>

        <FormTextInput
          name='orgNumber'
          parentForm={form}
          label='ORGANISATIONSNUMMER'
          errorsAlwaysTakeUpSpace={false}
          disabled={division?.mustInheritOrgNumber}
          options={{
            required: 'Organisationsnummer måste anges',
            /*             pattern: {
              value: /^[0-9]*$/i,
              message: 'Ogiltigt organisationsnummer',
            }, */
          }}
        />
        {division && !division.mustInheritOrgNumber && (
          <div
            onClick={() => {
              form.setValue('orgNumber', division.orgNumber);
            }}
            className='-mt-5  w-full rounded border border-gray-300   focus:outline-sky-500'
          >
            <div className='px-4 py-2'>
              <Label>ÄRV org.nr från sektion</Label>
            </div>
            <div className='flex w-full flex-row items-center justify-start gap-4 bg-sky-100 p-3 '>
              <Icon src={UserGroup} size={9} />
              <div className='clex flex-column shrink items-start justify-start'>
                <div className='bold'>{division.name}</div>
                <Description>{division.orgNumber}</Description>
              </div>
            </div>
          </div>
        )}

        <FormLabel label={'TILLÅT EVENT MED BILJETTKÖP'}>
          <ToggleRadioButtons
            selectOptions={canCreatePaidEvents}
            onChange={(event) =>
              setAllowCreatePaidEvent(
                Boolean(Number(event.currentTarget.value))
              )
            }
            selectedValue={Number(allowCreatePaidEvent)}
          />
        </FormLabel>
        {allowCreatePaidEvent && (
          <>
            <FormTextInput
              label='BOKFÖRINGSKONTO'
              name='accountNumber'
              parentForm={form}
              errorsAlwaysTakeUpSpace={false}
              maxLength={4}
              minLength={4}
              options={{
                required: 'Bokföringskonto måste anges',
                pattern: {
                  value: /^[0-9]*$/i,
                  message: 'Ogiltigt bokföringskonto',
                },
              }}
            />

            <FormTextInput
              name='resultUnit'
              label='RESULTATENHET'
              parentForm={form}
              errorsAlwaysTakeUpSpace={false}
              maxLength={2}
              minLength={2}
              options={{
                required: 'Resultatenehet måste anges',
                pattern: {
                  value: /^[0-9]*$/i,
                  message: 'Ogiltig resultatenehet',
                },
              }}
            />
          </>
        )}
      </Form>
    </InformationModal>
  );
};
